import { validate } from 'ui/DateSwiper/helpers';

const URL_PATTERN = new RegExp('^(https?:\\/\\/)?' // protocol
  + '((([sum-zа-яё\\d]([sum-zа-яё\\d-]*[a-zа-яё\\d])*)\\.)+[a-zа-яё]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-zа-яё\\d%_.~+]*)*' // port and path
  + '(\\?[;&a-zа-яё\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-zа-яё\\d_]*)?$', 'i'); // fragment locator

/**
 * Function used to inject validators into form controlled by redux-forms
 * @param getValidators {function} - a function returning dict of field to validators
 * @returns {function(values)} - takes values and returns large object with errors for every separate validator
 *
 * Iterate payFormFields that have validators and each validator individually
 * gather field errors in a large object and return it
 */
export const composeFormValidator = (getValidators) => (values) => {
  values = values || {};
  const validators = getValidators(values);
  return Object.keys(validators).reduce((errors, name) => {
    let fieldValidatorsList = validators[name] || [];
    if (typeof validators[name] === 'function') {
      fieldValidatorsList = [fieldValidatorsList];
    }
    fieldValidatorsList.some((fieldValidator) => {
      const fieldError = fieldValidator(values[name], values);
      if (fieldError) {
        errors[name] = fieldError;
        return true;
      }
      return false;
    });
    return errors;
  }, {});
};

/**
 * Add extra check to pass validator if value is empty
 */
const allowEmpty = (wrappedValidator) => (value) => (!value || value.length === 0 ? undefined : wrappedValidator(value));

export const composeRequiredValidator = (message) => (value) => (
  value && ( // value should not be empty
    typeof value !== 'string' // also it should either be not string
    || value.replace(/^\s*(.*?)\s*$/, '$1').length // or not just spaces
  ) ? undefined : message
);

/**
 * 10 digits starting with 9
 */
export const composePhoneValidator = (message) => allowEmpty((value) => (
  !/^9[0-9]{9}$/.test(value) ? message : undefined
));

export const composeEmailValidator = (message) => allowEmpty((value) => {
  if (!value) return undefined;

  return (
    /^([A-Za-zа-яА-Я0-9_-]+\.)*[A-Za-zа-яА-Я0-9_-]+@[A-Za-zа-яА-Я0-9_-]+(\.[A-Za-zа-яА-Я0-9_-]+)*\.[a-zA-Zа-яА-Я]{2,6}$/
      .test(value) ? undefined : message || ''
  );
});

export const composeWebsiteValidator = (message) => allowEmpty((value) => (
  /^((https?:)?\/\/)?[A-Za-zа-яА-Я0-9_-]+(\.[A-Za-zа-яА-Я0-9_-]+)*\.[a-zA-Zа-яА-Я]{2,6}$/
    .test(value) ? undefined : message || ''
));

export const composeCheckedValidator = (message) => (value) => (!value ? message : undefined);

/**
 * Валидатор текстовых полей
 * @param {string} message - Текст ошибки валидации поля
 * @param {('all' | 'en' | 'ru')} [lang = 'all'] - Параметр указывает текст какой языковой раскладки допустим
 * @param {number} [min = 0] - Минимальное количество символов
 * @param {number} [max = 60] - Максимальное количество символов
 * @returns {function(*=): undefined|*}
 */
export const composeTextValidator = ({ message = '', lang = 'all', min = 0, max = 60 }) => allowEmpty((value) => {
  let pattern = '(?![- ])[A-Za-zёЁА-Яа-я -]';

  if (lang === 'en') {
    pattern = '(?![- ])[A-Za-z -]';
  }

  if (lang === 'ru') {
    pattern = '(?![- ])[ёЁА-Яа-я -]';
  }

  const regExp = new RegExp(`^${pattern}{${min},${max}}$`);

  return regExp.test(value) ? undefined : message;
});

export const composeNameValidator = (message) => (value) => {
  if (!value) return undefined;
  if (value.replace(/ /g, '').length === 0) return message;
  return /^(?![- ])[A-Za-zёЁА-Яа-я -]{0,60}$/.test(value) ? undefined : message;
};

export const composeRussianValidator = (message) => allowEmpty((value) => (
  value.match(/[a-z]/gi)?.length ? message : undefined
));

export const composeSlugValidator = (message) => allowEmpty((value) => (
  value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, '').length === 0 ? message : undefined
));

export const composeNumberValidator = (message, { min = null, max = null }) => (value) => {
  /** validator does not make field required */
  if (!value && typeof value !== 'number') return undefined;
  if (min !== null && value < min || max !== null && value > max) return message;
  return undefined;
};

export const composeLengthValidator = (message, { min = null, max = null }) => (value) => {
  /** validator does not make field required */
  if (!value && typeof value !== 'string') return undefined;
  if (min !== null && value.length < min || max !== null && value.length > max) return message;
  return undefined;
};

export const composeUrlValidator = (message) => allowEmpty((value) => (
  !URL_PATTERN.test(value) ? message : undefined
));

export const composeDateValidator = (message) => (value) => {
  if (!value) return undefined;

  return validate(value) ? undefined : message;
};

/**
 * given key: value object with errors,
 * concatenates all the errors into single object, separated by dot and space
 */
export const mergeErrorObject = (errors, ignoreFields = []) => Object.keys(errors)
  .filter((key) => ignoreFields.indexOf(key) === -1)
  .map((key) => errors[key].replace(/\.$/, ''))
  .join('. ');
