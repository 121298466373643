import React from 'react';
import { inheritPropTypes } from 'utils/react';

/** forwardRef while keeping propTypes and defaultProps */
export const withForwardedRef = (WrappedComponent) => {
  const Component = React.forwardRef((props, ref) => (
    <WrappedComponent {...props} forwardedRef={ref || React.createRef()} />
  ));

  return inheritPropTypes(Component, WrappedComponent, { omit: ['forwardedRef'] });
};
