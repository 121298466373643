import { FieldCaption } from 'ui';
import { withRenderFlag } from './withRenderFlag';

const WrappedFieldCaption = withRenderFlag(FieldCaption);

export const withFieldCaption = (WrappedFieldComponent) => ({ className, hint, error, tooltip, ...fieldProps }) => (
  <div className={className}>
    <WrappedFieldComponent
      isError={!!error}
      {...fieldProps}
    />
    <WrappedFieldCaption
      hint={hint}
      error={error}
      tooltip={tooltip}
      shouldRender={error || hint}
    />
  </div>
);
