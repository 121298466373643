import { createSelector } from '@reduxjs/toolkit';
import { getModalName } from 'store/modal';
import { dateToLocaleSateString } from 'utils/format-string';

export const getForm = (state) => state.form;

export const getErrorMessage = (state) => getForm(state).errorMessage;
export const getFormField = createSelector([getForm, getModalName], (form, name) => form[name]);
export const getDateValue = (state, name) => getForm(state)?.[name]?.date || '';
export const getSerializedDateValue = (state, name) => dateToLocaleSateString(getDateValue(state, name))?.split('.')?.join('');
