import { createAction } from '@reduxjs/toolkit';
import { getModalName } from 'store/modal';
import { getFormField } from './selectors';

export const setFormValue = createAction('SET_FORM_VALUE');
export const setErrorMessage = createAction('SET_ERROR_MESSAGE');
export const resetForm = createAction('RESET_FORM');

export const setDateValue = (value) => (dispatch, getState) => {
  const field = getModalName(getState());
  const fieldData = getFormField(getState());

  dispatch(setFormValue({
    [field]: {
      ...fieldData,
      date: String(value),
    },
  }));
};

export const resetErrorMessage = () => (dispatch) => {
  dispatch(setErrorMessage(''));
};

export const setMonth = (value) => (dispatch, getState) => {
  const field = getModalName(getState());
  const fieldData = getFormField(getState());

  dispatch(setFormValue({
    [field]: {
      ...fieldData,
      month: value,
    },
  }));
};

export const setYear = (value) => (dispatch, getState) => {
  const field = getModalName(getState());
  const fieldData = getFormField(getState());

  dispatch(setFormValue({
    [field]: {
      ...fieldData,
      year: value,
    },
  }));
};

export const setDay = (value) => (dispatch, getState) => {
  const field = getModalName(getState());
  const fieldData = getFormField(getState());

  dispatch(setFormValue({
    [field]: {
      ...fieldData,
      day: value,
    },
  }));
};