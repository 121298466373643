import React from 'react';
import { useEventListener } from '../useEventListener';
import { getCurrentBreakpoint, simulateResize } from './helpers';

/**
 * Update each time a resolution changes
 */
export const useAdaptiveSize = (breakpoints) => {
  const [breakpoint, setBreakpoint] = React.useState(getCurrentBreakpoint(breakpoints));

  React.useEffect(() => {
    simulateResize();
  }, [breakpoint]);

  useEventListener('resize', React.useCallback(() => {
    /** return closest breakpoint that we surpassed in width */
    setBreakpoint(getCurrentBreakpoint(breakpoints));
  }, [breakpoints]), window);

  return breakpoint;
};
