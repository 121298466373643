class BodyScroll {
  scrollWidth = 0;

  body = (typeof document !== 'undefined' ? document : {}).body;

  lockScroll() {
    this.scrollWidth = BodyScroll.getScrollbarWidth();
    this.body.style.overflowY = 'hidden';
    this.body.style.marginRight = `${this.scrollWidth}px`;
  }

  unlockScroll() {
    this.body.style.marginRight = '';
    this.body.style.overflowY = '';
  }

  static getScrollbarWidth() {
    return typeof window !== 'undefined'
      ? window.innerWidth - document.documentElement.clientWidth
      : 0;
  }
}

const bodyScroll = new BodyScroll();

export default bodyScroll;
