import { INSUFFICIENT_BALANCE_WARNING } from 'constants/content';

import { formatPhone, mapDate } from 'utils/format-string';
import { SUBSCRIBER_INFO } from 'constants/forms';

export const subscriberInfoDataMapper = (data) => {
  if (!data) return;

  const mapNationalIdentifier = (id) => (id ? `${id.substring(0, 4)} ${id.substring(4)}` : undefined);

  return ({
    [SUBSCRIBER_INFO.GIVEN_NAME]: data?.[SUBSCRIBER_INFO.GIVEN_NAME],
    [SUBSCRIBER_INFO.FAMILY_NAME]: data?.[SUBSCRIBER_INFO.FAMILY_NAME],
    [SUBSCRIBER_INFO.MIDDLE_NAME]: data?.[SUBSCRIBER_INFO.MIDDLE_NAME],
    [SUBSCRIBER_INFO.EMAIL]: data?.[SUBSCRIBER_INFO.EMAIL],
    [SUBSCRIBER_INFO.SEX]: data?.[SUBSCRIBER_INFO.SEX],
    [SUBSCRIBER_INFO.BIRTH_PLACE]: data?.[SUBSCRIBER_INFO.BIRTH_PLACE],
    [SUBSCRIBER_INFO.BIRTH_DATE]: mapDate(data?.[SUBSCRIBER_INFO.BIRTH_DATE]),
    [SUBSCRIBER_INFO.DOCUMENT_TYPE]: data?.[SUBSCRIBER_INFO.DOCUMENT_TYPE],
    [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER]: mapNationalIdentifier(data?.[SUBSCRIBER_INFO.NATIONAL_IDENTIFIER]),
    [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE]: mapDate(data?.[SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE]),
    [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY]: data?.[SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY],
    [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE]: data?.[SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE],
    [SUBSCRIBER_INFO.ADDRESS]: data?.[SUBSCRIBER_INFO.ADDRESS],
    [SUBSCRIBER_INFO.POSTAL_CODE]: data?.[SUBSCRIBER_INFO.POSTAL_CODE],
    [SUBSCRIBER_INFO.COUNTRY]: data?.[SUBSCRIBER_INFO.COUNTRY],
    [SUBSCRIBER_INFO.STATE]: data?.[SUBSCRIBER_INFO.STATE],
    [SUBSCRIBER_INFO.CITY]: data?.[SUBSCRIBER_INFO.CITY],
    [SUBSCRIBER_INFO.DISTRICT]: data?.[SUBSCRIBER_INFO.DISTRICT],
    [SUBSCRIBER_INFO.STREET]: data?.[SUBSCRIBER_INFO.STREET],
    [SUBSCRIBER_INFO.HOUSE]: data?.[SUBSCRIBER_INFO.HOUSE],
    [SUBSCRIBER_INFO.BUILDING]: data?.[SUBSCRIBER_INFO.BUILDING],
    [SUBSCRIBER_INFO.APARTMENT]: data?.[SUBSCRIBER_INFO.APARTMENT],
  });
};

export const msisdnBalanceDataMapper = (data) => {
  if (!data) return;

  const ctn = data.msisdn.substring(1);
  const mapBalance = (value) => {
    if (!value) {
      return {
        error: true,
        errorText: INSUFFICIENT_BALANCE_WARNING,
      };
    }

    return `${value} ₽`;
  };

  return ({
    withdrawal_account: formatPhone(ctn),
    balance: mapBalance(data.balance_value),
    insufficient_balance: data.insufficient_balance_value,
    receiver: data.merchant_name,
    cost_amount: mapBalance(data.order_sum),
    commission: mapBalance(data.commission),
    payment_amount: mapBalance(data.total_sum),
  });
};
