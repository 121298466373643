import {
  BREAKPOINT_400,
  BREAKPOINT_502,
  BREAKPOINT_640,
  BREAKPOINT_768,
  BREAKPOINT_890,
  BREAKPOINT_1004,
  BREAKPOINT_1400,
  BREAKPOINT_1280,
} from './constants';
import { getAdaptivity } from '../selectors';

/**
 * Selectors for breakpoints, top resolution is INCLUDED in interval
  */
export const isScreenBelow400 = (state) => getAdaptivity(state) < BREAKPOINT_400;
export const isScreenBelow502 = (state) => getAdaptivity(state) < BREAKPOINT_502;
export const isScreenBelow640 = (state) => getAdaptivity(state) < BREAKPOINT_640;
export const isScreenBelow768 = (state) => getAdaptivity(state) < BREAKPOINT_768;
export const isScreenBelow890 = (state) => getAdaptivity(state) < BREAKPOINT_890;
export const isScreenBelow1004 = (state) => getAdaptivity(state) < BREAKPOINT_1004;
export const isScreenBelow1280 = (state) => getAdaptivity(state) < BREAKPOINT_1280;
export const isScreenBelow1400 = (state) => getAdaptivity(state) < BREAKPOINT_1400;
