import redaxios from 'redaxios';
import { IS_DEV, DEV_API, AUTHORIZATION_COOKIE } from 'constants/const';
import { getCookie } from 'utils/cookie';

export const request = (url, config) => redaxios(
  IS_DEV ? `${DEV_API}${url}` : url,
  config,
);

export const authorizedRequest = (url, config) => request(url, {
  headers: {
    Authorization: `Bearer ${getCookie(AUTHORIZATION_COOKIE)}`,
  },
  ...config,
});
