import { CONFIRM_CODE_ENDPOINT } from '../endpoints';
import { request } from '../wrapper';
import { AUTH_REG_ID_KEY } from 'constants/const';

/**
 * @param {string} code
 */
export const codeVerificationRequest = ({ otp, authReqId }) => request(CONFIRM_CODE_ENDPOINT, {
  params: {
    otp,
    [AUTH_REG_ID_KEY]: authReqId,
  },
});
