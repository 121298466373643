import { SUBSCRIBER_INFO_ENDPOINT } from '../endpoints';
import { request } from '../wrapper';
import { subscriberInfoDataMapper } from 'utils/mappers';

/**
 * @param {object} formData
 */
export const subscriberInfoRequest = (formData) => request(SUBSCRIBER_INFO_ENDPOINT, {
  method: 'POST',
  data: subscriberInfoDataMapper(formData),
});
