import { createReducer } from '@reduxjs/toolkit';

import { setModal } from './actions';

const initialState = {
  name: null,
  isOpen: false,
};

export const modalReducer = createReducer(initialState, {
  [setModal.type]: (state, action) => action.payload,
});
