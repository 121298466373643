import { isNullOrUndefined } from 'utils/fn';

/**
 * Takes a template like "Age: {{age}}" and replaces {{age}} on values
 * from the object `vars` with the appropriate fields
 * e.g.
 * templateParser('Age: {{age}}', { age: 21 }) => 'Age: 21'
 * @param {string} string
 * @param {object} vars
 */
export const templateParser = (string, vars) => {
  if (!string) return '';
  if (!vars) return string;
  return string.replace(/{{(\w+)}}/ig, (full, match) => vars[match]);
};

/**
 * Принимает в себя массив со склонениями слова из переменной dictionary и число,
 * к которому нужно применить необходимое склонение;
 * возвращает необходимое склонение слова из массива;
 * @param number {number} - Число;
 * @param nounArray {array} - Массив со склонениями слова;
 * @return {string} - Слово с необходимым склонением;
 */

export const declension = (nounArray, number) => {
  const num = Math.floor(number);
  const cases = [2, 0, 1, 1, 1, 2];
  const index = (num % 100 > 4 && num % 100 < 20)
    ? 2
    : cases[(num % 10 < 5) ? num % 10 : 5];
  return nounArray[index];
};

const dictionary = {
  seconds: ['секунду', 'секунды', 'секунд'],
};

export const translate = (word, number) => {
  if (!dictionary[word]) return null;
  return declension(dictionary[word], number);
};

/**
 * Функция приводит значение цены к формату, разделяя каждый третий разряд числа пробелом
 * @param {number} price - значение цены
 * @param {string} [unit] - размерность
 * @returns {string | null}
 */
export const formatPriceValues = (price, unit) => {
  if (!price) return null;

  let formattedPrice = String(price);

  if (price >= 1000) {
    formattedPrice = String(price).replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;');
  }

  return unit ? `${formattedPrice}&nbsp;${unit}` : formattedPrice;
};

/**
 * Приводит номер телефона +7 900 123 44 55
 * К формату для передачи в API 9001234455
 * @param {string} phoneNumber - номер телефона, введенный пользователем
 * @returns {string}
 */
export const unFormatPhone = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;

  return phoneNumber.replace('+7 ', '').split(' ').join('');
};

/**
 * Приводит ctn (номре телефона, полученный из API) 9001234455
 * К формату для отображения пользователю +7 900 123 44 55
 * @param {string} ctn - номер телефона, полученный из API
 * @returns {string}
 */
export const formatPhone = (ctn = '') => {
  if (ctn.length < 5) return ctn;

  const mainNumber = ctn.slice(ctn.length - 10, ctn.length);
  const code = mainNumber.slice(0, 3);
  const FstTelNum = mainNumber.slice(3, 6);
  const SndTelNum = mainNumber.slice(6, 8);
  const TrdTelNum = mainNumber.slice(8, 10);

  return `+7 ${code} ${FstTelNum} ${SndTelNum} ${TrdTelNum}`;
};

/* Приводит дату к формату DD.MM.YY */
export const dateToLocaleSateString = (date) => {
  if (!date) return;

  return new Date(date).toLocaleDateString();
};

/** Превращаем дату из 8 цифр DDMMYYYY в объект */
export const deserializeDate = (val) => ({
  day: val?.substring(0, 2),
  month: val?.substring(2, 4),
  year: val?.substring(4, 8),
});

export const valueToPixel = (value) => (isNullOrUndefined(value) ? null : `${value}px`);

export const mapDate = (value) => {
  if (!value) return;

  return `${value.substring(4, 8)}-${value.substring(2, 4)}-${value.substring(0, 2)}`;
};
