import { useRequest } from 'ahooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notificationConfirmationRequest } from 'api/requests';
import { setLoading } from 'store/global';
import { FAIL_ROUTE, SUCCESS_ROUTE } from 'constants/routes';

const POLLING_TIMEOUT = 70000;
const POLLING_INTERVAL = 1000;

export const usePollingConfirmation = (onAfter) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let timeoutFN;

  const { run: startPolling, cancel: cancelPolling } = useRequest(notificationConfirmationRequest, {
    manual: true,
    pollingInterval: POLLING_INTERVAL,
    onSuccess: ({ data }) => {
      if (data.ready) {
        clearTimeout(timeoutFN);
        cancelPolling();
        dispatch(setLoading(false));
        onAfter?.();
        navigate(SUCCESS_ROUTE);
      }
    },
    onError: () => {
      clearTimeout(timeoutFN);
      cancelPolling();
      dispatch(setLoading(false));
      onAfter?.();
      navigate(FAIL_ROUTE);
    },
  });

  const start = (param) => {
    startPolling(param);
    dispatch(setLoading(true));
    timeoutFN = setTimeout(() => {
      cancelPolling();
      dispatch(setLoading(false));
      onAfter?.();
      navigate(FAIL_ROUTE);
    }, POLLING_TIMEOUT);
  };

  return {
    startPolling: start,
  };
};
