import {
  composeNameValidator,
  composeRequiredValidator,
  composeEmailValidator, composeDateValidator, composeCheckedValidator,
} from 'utils/validators';

import {
  SUBSCRIBER_INFO,
  FORM_VALIDATION,
  DEFAULT_VALUES,
  PERSONAL_DATA_CHECKBOX,
} from 'constants/forms';

export const notEmpty = composeRequiredValidator(FORM_VALIDATION.required);

export const validators = () => ({
  [SUBSCRIBER_INFO.GIVEN_NAME]: [notEmpty, composeNameValidator(FORM_VALIDATION[SUBSCRIBER_INFO.GIVEN_NAME])],
  [SUBSCRIBER_INFO.FAMILY_NAME]: [notEmpty, composeNameValidator(FORM_VALIDATION[SUBSCRIBER_INFO.FAMILY_NAME])],
  [SUBSCRIBER_INFO.MIDDLE_NAME]: [notEmpty, composeNameValidator(FORM_VALIDATION[SUBSCRIBER_INFO.MIDDLE_NAME])],
  [SUBSCRIBER_INFO.EMAIL]: [composeEmailValidator(FORM_VALIDATION[SUBSCRIBER_INFO.EMAIL])],
  [SUBSCRIBER_INFO.BIRTH_DATE]: [notEmpty, composeDateValidator(FORM_VALIDATION.date)],
  [SUBSCRIBER_INFO.SEX]: [notEmpty],
  [SUBSCRIBER_INFO.DOCUMENT_TYPE]: [notEmpty],
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER]: [notEmpty],
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE]: [notEmpty, composeDateValidator(FORM_VALIDATION.date)],
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY]: [notEmpty],
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE]: [notEmpty],
  [SUBSCRIBER_INFO.ADDRESS]: [notEmpty],
  [SUBSCRIBER_INFO.POSTAL_CODE]: [notEmpty],
  [SUBSCRIBER_INFO.COUNTRY]: [notEmpty],
  [SUBSCRIBER_INFO.STATE]: [notEmpty],
  [SUBSCRIBER_INFO.CITY]: [notEmpty],
  [SUBSCRIBER_INFO.DISTRICT]: [notEmpty],
  [SUBSCRIBER_INFO.STREET]: [notEmpty],
  [SUBSCRIBER_INFO.HOUSE]: [notEmpty],
  [SUBSCRIBER_INFO.BUILDING]: [notEmpty],
  [SUBSCRIBER_INFO.APARTMENT]: [notEmpty],
});

export const getValidators = (fields, validatorsObject = validators()) => fields.reduce((acc, field) => ({
  ...acc,
  [field]: validatorsObject[field],
}), {});

export const getDefaultValues = (fields) => {
  if (!fields) return;

  return fields.reduce((acc, field) => ({
    ...acc,
    [field]: DEFAULT_VALUES[field],
  }), {});
};

export const getPersonalDataValidator = (validateMsg) => () => ({
  [PERSONAL_DATA_CHECKBOX]: [
    composeCheckedValidator(validateMsg.required),
  ],
});
