import { useEffect } from 'react';
import bodyScroll from 'utils/bodyScroll';

export const useLockScroll = (condition) => {
  useEffect(() => {
    if (condition) {
      bodyScroll.lockScroll();
    } else {
      bodyScroll.unlockScroll();
    }
  }, [condition]);

  useEffect(() => () => bodyScroll.unlockScroll(), []);
};
