import { PAY_ORDER_ENDPOINT } from '../endpoints';
import { authorizedRequest } from '../wrapper';
import { PAYMENT_SESSION_ID } from 'constants/const';

/**
 * @param {('balance' | undefined)} method - Метод оплаты, to be updated
 * */
export const payOrderRequest = (method) => authorizedRequest(PAY_ORDER_ENDPOINT, {
  method: 'POST',
  data: {
    method,
    session_id: localStorage.getItem(PAYMENT_SESSION_ID),
  },
});
