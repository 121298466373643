export const PERSONAL_DATA_CHECKBOX = 'personal-data-checkbox';
export const CODE = 'code';
export const CONFIRM_ID = 'confirm_id';
export const PROMO_MESSAGES_CHECKBOX = 'promo-messages-checkbox';

export const MSISDN_KEY = 'msisdn';

export const SUBSCRIBER_INFO = {
  FAMILY_NAME: 'family_name',
  GIVEN_NAME: 'given_name',
  MIDDLE_NAME: 'middle_name',
  EMAIL: 'email',
  BIRTH_DATE: 'birthdate',
  BIRTH_PLACE: 'birthplace',
  SEX: 'sex',
  DOCUMENT_TYPE: 'document_type',
  NATIONAL_IDENTIFIER: 'national_identifier',
  NATIONAL_IDENTIFIER_DATE: 'national_identifier_date',
  NATIONAL_IDENTIFIER_AUTHORITY: 'national_identifier_authority',
  NATIONAL_IDENTIFIER_AUTHORITY_CODE: 'national_identifier_authority_code',
  ADDRESS: 'address',
  POSTAL_CODE: 'postal_code',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  DISTRICT: 'district',
  STREET: 'street',
  HOUSE: 'houseno_or_housename',
  BUILDING: 'building',
  APARTMENT: 'apartment',
};

export const DEFAULT_VALUES = {
  [MSISDN_KEY]: '',
  [SUBSCRIBER_INFO.GIVEN_NAME]: '',
  [SUBSCRIBER_INFO.FAMILY_NAME]: '',
  [SUBSCRIBER_INFO.MIDDLE_NAME]: '',
  [SUBSCRIBER_INFO.EMAIL]: '',
  [SUBSCRIBER_INFO.BIRTH_DATE]: null,
  [SUBSCRIBER_INFO.DOCUMENT_TYPE]: '',
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER]: '',
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE]: '',
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY]: '',
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE]: '',
  [SUBSCRIBER_INFO.ADDRESS]: '',
  [SUBSCRIBER_INFO.POSTAL_CODE]: '',
  [SUBSCRIBER_INFO.COUNTRY]: '',
  [SUBSCRIBER_INFO.STATE]: '',
  [SUBSCRIBER_INFO.CITY]: '',
  [SUBSCRIBER_INFO.DISTRICT]: '',
  [SUBSCRIBER_INFO.STREET]: '',
  [SUBSCRIBER_INFO.HOUSE]: '',
  [SUBSCRIBER_INFO.BUILDING]: '',
  [SUBSCRIBER_INFO.APARTMENT]: '',
};

export const UPDATE_FORM = {
  [SUBSCRIBER_INFO.GIVEN_NAME]: {
    id: SUBSCRIBER_INFO.GIVEN_NAME,
    name: SUBSCRIBER_INFO.GIVEN_NAME,
    label: 'Имя',
  },
  [SUBSCRIBER_INFO.FAMILY_NAME]: {
    id: SUBSCRIBER_INFO.FAMILY_NAME,
    name: SUBSCRIBER_INFO.FAMILY_NAME,
    label: 'Фамилия',
  },
  [SUBSCRIBER_INFO.MIDDLE_NAME]: {
    id: SUBSCRIBER_INFO.MIDDLE_NAME,
    name: SUBSCRIBER_INFO.MIDDLE_NAME,
    label: 'Отчество',
  },
  [SUBSCRIBER_INFO.EMAIL]: {
    id: SUBSCRIBER_INFO.EMAIL,
    name: SUBSCRIBER_INFO.EMAIL,
    label: 'Email',
    description: 'Мы отправим вам письмо для подтверждения',
  },
  [SUBSCRIBER_INFO.DOCUMENT_TYPE]: {
    id: SUBSCRIBER_INFO.DOCUMENT_TYPE,
    name: SUBSCRIBER_INFO.DOCUMENT_TYPE,
    label: 'Тип документа',
    placeholder: 'Укажите тип документа',
    options: [{
      value: 'passport',
      displayValue: 'Паспорт РФ',
    }, {
      value: 'international_passport',
      displayValue: 'Загранпаспорт РФ',
    }],
  },
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER]: {
    id: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER,
    name: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER,
    label: 'Серия и номер документа',
    mask: '____ ______',
  },
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE]: {
    id: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE,
    name: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE,
    label: 'Дата выдачи документа',
    placeholder: '__.__.____',
  },
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY]: {
    id: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY,
    name: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY,
    label: 'Кем выдан',
  },
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE]: {
    id: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE,
    name: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE,
    label: 'Код подразделения',
    mask: '___-___',
  },
  [SUBSCRIBER_INFO.BIRTH_DATE]: {
    id: SUBSCRIBER_INFO.BIRTH_DATE,
    name: SUBSCRIBER_INFO.BIRTH_DATE,
    label: 'Дата рождения',
    placeholder: '__.__.____',
  },
  [SUBSCRIBER_INFO.BIRTH_PLACE]: {
    id: SUBSCRIBER_INFO.BIRTH_PLACE,
    name: SUBSCRIBER_INFO.BIRTH_PLACE,
    label: 'Место рождения',
  },
  [SUBSCRIBER_INFO.SEX]: {
    id: SUBSCRIBER_INFO.SEX,
    name: SUBSCRIBER_INFO.SEX,
    placeholder: 'Укажите пол',
    label: 'Пол',
    options: [{
      value: 'Ж',
      displayValue: 'Женский',
    }, {
      value: 'М',
      displayValue: 'Мужской',
    }],
  },
  [SUBSCRIBER_INFO.ADDRESS]: {
    id: SUBSCRIBER_INFO.ADDRESS,
    name: SUBSCRIBER_INFO.ADDRESS,
    label: 'Адрес регистрации',
  },
  [SUBSCRIBER_INFO.POSTAL_CODE]: {
    id: SUBSCRIBER_INFO.POSTAL_CODE,
    name: SUBSCRIBER_INFO.POSTAL_CODE,
    label: 'Индекс',
  },
  [SUBSCRIBER_INFO.COUNTRY]: {
    id: SUBSCRIBER_INFO.COUNTRY,
    name: SUBSCRIBER_INFO.COUNTRY,
    label: 'Страна',
  },
  [SUBSCRIBER_INFO.STATE]: {
    id: SUBSCRIBER_INFO.STATE,
    name: SUBSCRIBER_INFO.STATE,
    label: 'Регион',
  },
  [SUBSCRIBER_INFO.CITY]: {
    id: SUBSCRIBER_INFO.CITY,
    name: SUBSCRIBER_INFO.CITY,
    label: 'Город',
  },
  [SUBSCRIBER_INFO.DISTRICT]: {
    id: SUBSCRIBER_INFO.DISTRICT,
    name: SUBSCRIBER_INFO.DISTRICT,
    label: 'Район',
  },
  [SUBSCRIBER_INFO.STREET]: {
    id: SUBSCRIBER_INFO.STREET,
    name: SUBSCRIBER_INFO.STREET,
    label: 'Улица',
  },
  [SUBSCRIBER_INFO.HOUSE]: {
    id: SUBSCRIBER_INFO.HOUSE,
    name: SUBSCRIBER_INFO.HOUSE,
    label: 'Номер дома',
  },
  [SUBSCRIBER_INFO.BUILDING]: {
    id: SUBSCRIBER_INFO.BUILDING,
    name: SUBSCRIBER_INFO.BUILDING,
    label: 'Корпус',
  },
  [SUBSCRIBER_INFO.APARTMENT]: {
    id: SUBSCRIBER_INFO.APARTMENT,
    name: SUBSCRIBER_INFO.APARTMENT,
    label: 'Квартира',
  },
};

export const FORM_VALIDATION = {
  required: 'Поле должно быть заполнено',
  [MSISDN_KEY]: 'Укажите номер телефона',
  [SUBSCRIBER_INFO.GIVEN_NAME]: 'Укажите ваше имя',
  [SUBSCRIBER_INFO.FAMILY_NAME]: 'Укажите вашу фамилию',
  [SUBSCRIBER_INFO.MIDDLE_NAME]: 'Укажите ваше отчество',
  [SUBSCRIBER_INFO.EMAIL]: 'Укажите почту',
  [SUBSCRIBER_INFO.BIRTH_PLACE]: 'Укажите ваше место рождения',
  date: 'Некорректная дата',
  [SUBSCRIBER_INFO.DOCUMENT_TYPE]: 'Укажите тип документа',
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER]: 'Укажите серию и номер документа',
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY]: 'Укажите, кем выдан документ',
  [SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_AUTHORITY_CODE]: 'Укажите код подразделения',
  [SUBSCRIBER_INFO.ADDRESS]: 'Укажите адрес регистрации',
  [SUBSCRIBER_INFO.POSTAL_CODE]: 'Укажите индекс',
  [SUBSCRIBER_INFO.COUNTRY]: 'Укажите страну',
  [SUBSCRIBER_INFO.STATE]: 'Укажите регион',
  [SUBSCRIBER_INFO.CITY]: 'Укажите город',
  [SUBSCRIBER_INFO.DISTRICT]: 'Укажите район',
  [SUBSCRIBER_INFO.STREET]: 'Укажите улицу',
  [SUBSCRIBER_INFO.HOUSE]: 'Укажите номер дома',
  [SUBSCRIBER_INFO.BUILDING]: 'Укажите корпус',
  [SUBSCRIBER_INFO.APARTMENT]: 'Укажите номер квартиры',
};

export const AUTH_FORM = {
  [MSISDN_KEY]: {
    id: MSISDN_KEY,
    name: MSISDN_KEY,
    label: 'Номер телефона',
    placeholder: '+7 900 000-00-00',
  },
};
