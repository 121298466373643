import PropTypes from 'prop-types';
import { isValidElementType } from 'react-is';

const SecretPrevProps = '__prevRenderProps';
const PropTypesNewRef = PropTypes.shape({ current: PropTypes.any });

/**
 * Wraps parametrized HOC function allowing to call it both with param, or without it, as follows:
 * - HOC(params)(WrappedComponent)
 * - HOC(WrappedComponents)
 */
export const allowParamOmit = (HOC) => (arg) => (isValidElementType(arg) ? HOC()(arg) : HOC(arg));

/**
 * Copies propTypes from Ancestor to Component, omitting some of the params
 */
export const inheritPropTypes = (Component, Ancestor, { omit = [] } = {}) => {
  Component.propTypes = { ...Ancestor.propTypes };
  Component.defaultProps = { ...Ancestor.defaultProps };

  omit.forEach((propName) => {
    delete Component.propTypes[propName];
    delete Component.defaultProps[propName];
  });

  return Component;
};

export const CustomPropTypes = {
  newRef: PropTypesNewRef,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypesNewRef,
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  oneOfValues: (obj) => PropTypes.oneOf(Object.values(obj)),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

/**
 * Wrapper around getDerivedStateFromProps to only react on props change and ignore setState calls
 */
export const ignoreState = (getDerivedStateFromProps) => (nextProps, prevState) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (prevState[SecretPrevProps] !== nextProps) {
    return {
      ...getDerivedStateFromProps(nextProps, prevState),
      [SecretPrevProps]: nextProps,
    };
  }

  return null;
};
