import { AUTHORIZATION_ENDPOINT } from '../endpoints';
import { request } from '../wrapper';

/**
 * @param {string} msisdn
 */
export const authorizationRequest = ({ msisdn }) => request(AUTHORIZATION_ENDPOINT, {
  method: 'POST',
  data: { msisdn },
});
