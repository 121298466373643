import { adaptivityReducer } from './adaptivity';
import { modalReducer } from './modal';
import { formReducer } from './form';
import { globalReducer } from './global';
import { BREAKPOINT_1004 } from 'store/adaptivity/constants';

const initialState = {
  adaptivity: BREAKPOINT_1004,
};

// eslint-disable-next-line default-param-last
export const createRootReducer = () => (state = initialState, action) => ({
  ...state,
  adaptivity: adaptivityReducer(state.adaptivity, action),
  modal: modalReducer(state.modal, action),
  form: formReducer(state.form, action),
  global: globalReducer(state.global, action),
});
