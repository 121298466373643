import { AUTH_RESULT, AUTH_TYPES, RESULT_KEY } from './const';

export const ROOT_ROUTE = '/';
export const ANY_ROUTE = '/*';
export const ERROR_ROUTE = '/error';

export const CONFIRM_ROUTE = '/confirm';
export const NEGATIVE_ROUTE = '/result';
export const UPDATE_REQUEST = '/update-request';
export const UPDATE_PD = '/update-pd';
export const DATA_UPDATE = '/data-update';
export const NUMBERS_LIST_UPDATE = '/numbers-list-update';
export const DATA_COMPLETION = '/data-completion';
export const COMPLETE_ROUTE = '/complete';
export const BIRTHDATE_VERIFICATION_ROUTE = '/verify-birthdate';
export const CODE_VERIFICATION_ROUTE = '/verify-code';

export const SUCCESS_ROUTE = `${COMPLETE_ROUTE}?key=${RESULT_KEY.SUCCESS}`;
export const FAIL_ROUTE = `${COMPLETE_ROUTE}?key=${RESULT_KEY.TIMEOUT}`;
export const CODE_TIMEOUT_ROUTE = `${COMPLETE_ROUTE}?key=${RESULT_KEY.CODE_TIMEOUT}`;
export const CODE_FAIL_ROUTE = `${COMPLETE_ROUTE}?key=${RESULT_KEY.CODE_FAIL}`;
export const EXPIRED_ROUTE = `${COMPLETE_ROUTE}?key=${RESULT_KEY.EXPIRED}`;

export const PAYMENT_METHOD = '/payment-method';
export const CONFIRM_PAYMENT = '/payment';
export const ADD_PAYMENT_METHOD = '/add-payment-method';

export const FAKE_STORE_PAGE = '/store-demo';
export const PAYMENT_SUCCESS = '/payment-success';
export const PAYMENT_ERROR = '/payment-error';

export const OUTDATED_LINK_ERROR = '/outdated-link-error';

/* AUTHORIZATION */
export const AUTH_START = '/nologinhint';
export const CONFIRM_AUTH = '/confirm-authorization';
export const PUSH_AUTH = `${CONFIRM_AUTH}?type=${AUTH_TYPES.PUSH}`;
export const PUSH_PIN_AUTH = `${CONFIRM_AUTH}?type=${AUTH_TYPES['PUSH+PIN']}`;
export const PUSH_SETUP_PIN_AUTH = `${CONFIRM_AUTH}?type=${AUTH_TYPES['PUSH+PIN-SETUP']}`;
export const USSD_AUTH = `${CONFIRM_AUTH}?type=${AUTH_TYPES.USSD}`;
export const SMS_URL_AUTH = `${CONFIRM_AUTH}?type=${AUTH_TYPES['SMS+URL']}`;
export const SMS_AUTH = `${CONFIRM_AUTH}?type=${AUTH_TYPES.SMS}`;

export const AUTH_RESULT_ROUTE = '/auth-result';
export const AUTH_SUCCESS = `${AUTH_RESULT_ROUTE}?type=${AUTH_RESULT.SUCCESS}`;
export const AUTH_PUSH_DENIED = `${AUTH_RESULT_ROUTE}?type=${AUTH_RESULT.PUSH_DENIED}`;
