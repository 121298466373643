import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';

import { payOrderRequest, paymentOrderStatusRequest, msisdnBalanceRequest } from 'api/requests';

import { MODALS, PAYMENT_CONFIRMED, PAYMENT_METHODS, PAYMENT_SESSION_ID } from 'constants/const';
import { INSUFFICIENT_BALANCE_ERROR } from 'constants/content';
import { NEGATIVE_ROUTE, PAYMENT_ERROR } from 'constants/routes';

import { getIsLoading, getLoadingMessage, setLoading, setLoadingMessage } from 'store/global';
import { closeModal, openModal } from 'store/modal';

import { msisdnBalanceDataMapper } from 'utils/mappers';

let timeoutFN;

export const usePaymentConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const loadingMessage = useSelector(getLoadingMessage);

  const stopPreloader = useCallback(() => {
    dispatch(setLoading(false));
    if (loadingMessage) {
      dispatch(setLoadingMessage(''));
    }
  }, []);

  /* Запрашиваем данные о платеже, получаем sessionId */
  const { run: requestData, data: balanceData } = useRequest(msisdnBalanceRequest, {
    manual: true,
    onSuccess: ({ data }) => {
      localStorage.setItem(PAYMENT_SESSION_ID, data.session_id);
    },
    onError: () => {
      navigate(NEGATIVE_ROUTE);
    },
    onFinally: stopPreloader,
  });

  /* Ставим Preloader до тех пор, пока не получим данные о платеже */
  const onWillMount = () => {
    if (isLoading) return;

    dispatch(setLoading(true));
  };

  /* Открываем модалку подтверждения платежа */
  const handleClick = () => {
    dispatch(openModal(MODALS.CONFIRM_PAYMENT));
  };

  /* Запрашиваем статус оплаты с помощью поллинга */
  const { run: startPolling, cancel: cancelPolling } = useRequest(paymentOrderStatusRequest, {
    manual: true,
    pollingInterval: 1000,
    onSuccess: ({ data }) => {
      /* Payment Success */
      if (data.redirect_url) {
        localStorage.removeItem(PAYMENT_SESSION_ID);
        cancelPolling();
        window.location.href = data.redirect_url;
      }
    },
    onFinally: (params, data, e) => {
      if (e) {
        clearTimeout(timeoutFN);
        cancelPolling();
        stopPreloader();

        if (payOrderData?.data?.redirect_url) {
          localStorage.removeItem(PAYMENT_SESSION_ID);
          window.location.href = payOrderData.data.redirect_url;
        }
      }
    },
  });

  /* Начинаем платеж */
  const { run: startPayment, data: payOrderData } = useRequest(payOrderRequest, {
    manual: true,
    onSuccess: ({ data }) => {
      startPolling();

      timeoutFN = setTimeout(() => {
        if (isLoading) {
          cancelPolling();
          stopPreloader();
          window.location.href = data.redirect_url;
        }
      }, data.payment_timeout_sec * 1000);
    },
    onError: (({ data }) => {
      if (data?.error === INSUFFICIENT_BALANCE_ERROR) {
        stopPreloader();
        navigate(PAYMENT_ERROR);
      }
    }),
  });

  /* Подтверждаем оплату в модалке */
  const handleConfirm = () => {
    sessionStorage.setItem(PAYMENT_CONFIRMED, 'true');
    dispatch(closeModal());
    dispatch(setLoading(true));
    dispatch(setLoadingMessage('Подождите, пожалуйста...'));
  };

  const userConfirmedPayment = sessionStorage.getItem(PAYMENT_CONFIRMED) === 'true';

  /* Ждем подтверждения в модалке, чтобы начать платеж */
  useEffect(() => {
    if (userConfirmedPayment) {
      startPayment(PAYMENT_METHODS.BALANCE);
      sessionStorage.removeItem(PAYMENT_CONFIRMED);
    }
  }, [userConfirmedPayment]);

  return {
    onWillMount,
    requestData,
    data: msisdnBalanceDataMapper(balanceData?.data),
    onClick: handleClick,
    onConfirm: handleConfirm,
  };
};
