export const withRenderFlag = (WrappedComponent, SuspenseComponent = null) => {
  const ConditionalRenderer = ({ shouldRender, ...props }) => (
    shouldRender ? <WrappedComponent {...props} /> : SuspenseComponent
  );

  /**
   * Override Connector's name to withRenderFlag(%Component%)
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ConditionalRenderer.displayName = `withRenderFlag(${WrappedComponentName})`;
  }
  return ConditionalRenderer;
};
