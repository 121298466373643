import { configureStore } from '@reduxjs/toolkit';
import { createRootReducer } from './reducer';

/**
 * Initialize Store Function
 * @param {object | boolean} devToolsOptions
 * @param {object | function} reducer
 * @returns {EnhancedStore<any, AnyAction, [ThunkMiddlewareFor<any>]>}
 */
// eslint-disable-next-line default-param-last
export const initializeStore = (devToolsOptions = true, reducer) => configureStore({
  reducer: reducer || createRootReducer(),
  devTools: devToolsOptions,
});
