import { CONFIRM_NOTIFICATION_ENDPOINT } from '../endpoints';
import { request } from '../wrapper';
import { AUTH_REG_ID_KEY } from 'constants/const';

/**
 * @param {string} id
 */
export const notificationConfirmationRequest = (id) => request(CONFIRM_NOTIFICATION_ENDPOINT, {
  params: {
    [AUTH_REG_ID_KEY]: id,
  },
});
