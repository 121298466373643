import { useEffect, useMemo, useState } from 'react';

let timerId = null;

/**
 * @typedef {Object} TimerResult
 * @property {number} time - Оставшееся время таймера в секундах
 * @property {string} timeMessage - Сообщение для отображения во время отсчета таймера
 * @property {function} resetTime - Функция для сброса таймера
 */

/**
 * @param {number} timeout - Время работы таймера в секундах
 * @param {string} [additionalMessage] - Сообщение для отображения в случае истечения таймера
 * @return {TimerResult}
 * */

export const useTimer = (timeout, additionalMessage) => {
  const [time, setTime] = useState(timeout);

  const timeMessage = useMemo(() => (
    time === 0
      ? additionalMessage || ''
      : `Осталось: ${time} cек`
  ), [time, timeout]);

  useEffect(() => {
    if (time > 0) {
      timerId = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time]);

  const resetTime = () => setTime(timeout);

  useEffect(() => {
    if (time <= 0) {
      resetTime();

      return () => clearTimeout(timerId);
    }
  }, []);

  return { timeMessage, resetTime, time };
};
