import { connect } from 'react-redux';

import { isScreenBelow640 } from 'store/adaptivity/selectors';

import { ContentFrame } from 'components/ContentFrame';
import { Footer, Layout } from 'ui';

export const withLayout = (centered, displayLogo = true) => (WrappedComponent) => {
  const Component = ({ isMobile, ...restProps }) => (
    <Layout>
      <ContentFrame displayLogo={displayLogo} centered={centered}>
        <WrappedComponent {...restProps} />
      </ContentFrame>

      <Footer />
    </Layout>
  );

  /**
   * Override Connector's name to withLayout(%Component%)
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    Component.displayName = `withLayout(${WrappedComponentName})`;
  }

  const mapStateToProps = (state) => ({
    isMobile: isScreenBelow640(state),
  });

  return connect(mapStateToProps)(Component);
};
