import { SUBSCRIBER_INFO } from 'constants/forms';
import { getCookie } from 'utils/cookie';

export const IOS = 'ios';
export const ANDROID = 'android';
export const WINDOWS = 'windows';
export const UNDEFINED = 'undefined';

export const DEV_API = process.env.DEV_API || '';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD_ENV = process.env.NODE_ENV === 'production';

export const KEYBOARD_BACKSPACE_KEYCODE = 8;

export const STATUS_INFO_QUERY = 'statusInfo';

/* Cookies */
export const TESTING_COOKIE = 'test_8cfcc6';

export const AUTHORIZATION_COOKIE = '_mc_authorize_0189871b';
export const AUTHORIZATION_MODE_COOKIE = '_mc_authorize_mode_0189871b';
export const SITE_LABEL_COOKIE = '_mc_sp_site_label_0189871b';
export const SITE_URL_COOKIE = '_mc_sp_site_url_0189871b';
export const SITE_HOME_PAGE_COOKIE = '_mc_sp_home_page_0189871b';
export const SITE_ADVERT_CONSENT = '_mc_consents_adv_consent_0189871b';
export const SITE_CONSENT_URL = '_mc_consents_consent_url_0189871b';
export const SITE_CONSENT_WITH_KYC = '_mc_consents_with_kyc_0189871b';
export const SITE_WRONG_CLAIMS_COOKIE = '_mc_unreceived_claims_0189871b';
export const SITE_REDIRECT_URL_COOKIE = '_mc_redirect_url_0189871b';
export const SITE_CONSENT_WITH_OTP = '_mc_consents_with_otp_0189871b';

export const PAYMENT_SESSION_ID = 'payment_session_id';
export const PAYMENT_CONFIRMED = 'payment_confirmed';

export const AUTH_REG_ID_KEY = 'auth_req_id';

export const RESULT_KEY = {
  SUCCESS: 'success',
  CODE_FAIL: 'code_fail',
  CODE_TIMEOUT: 'code_timeout',
  TIMEOUT: 'timeout',
  EXPIRED: 'expired',
};

/* По наличию в url параметра k=1 мы понимаем, что оператор Мегафон;
 в дальнейшем возможно расширение для других значений этого ключа */
export const IS_MEGAFON = 'k';

export const IS_RAIFFEISEN = getCookie(SITE_LABEL_COOKIE) === 'Райффайзенбанк';

export const AUTH_TYPES = {
  SMS: 'sms',
  'SMS+URL': 'sms-url',
  PUSH: 'push',
  'PUSH+PIN': 'push-pin',
  'PUSH+PIN-SETUP': 'push-setup-pin',
  USSD: 'ussd',
};

export const AUTH_RESULT = {
  SUCCESS: 'success',
  PUSH_DENIED: 'push-denied',
};

export const MODALS = {
  NATIONAL_IDENTIFIER_DATE: SUBSCRIBER_INFO.NATIONAL_IDENTIFIER_DATE,
  BIRTH_DATE: SUBSCRIBER_INFO.BIRTH_DATE,
  CONFIRM_PAYMENT: 'confirmPayment',
};

export const PAYMENT_METHODS = {
  BALANCE: 'balance',
};
