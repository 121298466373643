import React from 'react';
import { IS_DEV } from 'constants/const';
import { AppContainer, hot } from 'react-hot-loader';

/**
 * HOC that provides
 * HotModuleReload + ErrorBoundary
 * the actual highest parental module must be passed for HMR to work
 */
export const withHMR = (module) => (WrappedComponent) => {
  if (IS_DEV) {
    const RedBox = require('redbox-react').default;

    const HMRProvider = (props) => (
      <AppContainer errorReporter={RedBox}>
        <WrappedComponent {...props} />
      </AppContainer>
    );

    return hot(module)(HMRProvider);
  }

  return WrappedComponent;
};
