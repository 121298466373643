import { useEffect, useState } from 'react';

import { UNDEFINED } from 'constants/const';
import { detectMobileOS } from 'utils/checkUserAgent';

export const usePlatform = () => {
  const [platform, setPlatform] = useState(UNDEFINED);

  useEffect(() => {
    setPlatform(detectMobileOS() || UNDEFINED);
  }, []);

  return platform;
};
