import { createReducer } from '@reduxjs/toolkit';

import { setLoading, setLoadingMessage } from './actions';

const initialState = {
  loading: false,
  message: '',
};

export const globalReducer = createReducer(initialState, {
  [setLoading.type]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [setLoadingMessage.type]: (state, action) => ({
    ...state,
    message: action.payload,
  }),
});