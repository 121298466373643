import { CONFIRM_ENDPOINT } from '../endpoints';
import { request } from '../wrapper';

/**
 * @param {string} code
 * @param {object} formData
 * @param {boolean} isConsent
 */
export const confirmationRequest = ({ code, formData, isConsent = false }) => request(CONFIRM_ENDPOINT, {
  method: 'POST',
  data: {
    code,
    adv_consent: isConsent,
    kyc_data: formData,
    /* temporarily */
    home_page: null,
  },
});
