import { useCallback, useState } from 'react';

export const useSelect = ({ onChange }) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedInput, setSelectedInput] = useState({
    value: '',
    displayValue: '',
  });

  const toggleOptions = useCallback(() => setOpen((prev) => !prev), []);
  const closeOptions = useCallback(() => setOpen(false), []);

  const handleClick = useCallback((option) => {
    setSelectedInput(option);
    onChange(option.value);
    closeOptions();
  }, []);

  return {
    isOpen,
    selectedInput,
    inputValue: selectedInput.value,
    inputDisplayValue: selectedInput.displayValue,
    toggleOptions,
    onClick: handleClick,
  };
};
