import { createReducer } from '@reduxjs/toolkit';

import { setErrorMessage, setFormValue, resetForm } from './actions';

const initialState = {
  errorMessage: '',
};

export const formReducer = createReducer(initialState, {
  [setFormValue.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [setErrorMessage.type]: (state, action) => ({
    ...state,
    errorMessage: action.payload,
  }),
  [resetForm.type]: () => initialState,
});
