import { createAction } from '@reduxjs/toolkit';

export const setModal = createAction('SET_MODAL');

export const openModal = (name) => (dispatch) => {
  dispatch(setModal({
    name,
    isOpen: true,
  }));
};

export const closeModal = () => (dispatch) => {
  dispatch(setModal({
    name: null,
    isOpen: false,
  }));
};