import { getCookie } from 'utils/cookie';
import {
  AUTH_RESULT,
  AUTH_TYPES,
  IS_RAIFFEISEN,
  SITE_CONSENT_URL,
  SITE_HOME_PAGE_COOKIE,
  SITE_LABEL_COOKIE,
  SITE_REDIRECT_URL_COOKIE,
  SITE_URL_COOKIE,
} from 'constants/const';

const label = getCookie(SITE_LABEL_COOKIE);
const url = getCookie(SITE_URL_COOKIE);
const homePage = getCookie(SITE_HOME_PAGE_COOKIE);
const redirectUrl = getCookie(SITE_REDIRECT_URL_COOKIE);
const consentUrl = getCookie(SITE_CONSENT_URL);

export const DEFAULT_ERROR = {
  icon: 'forbidden',
  title: 'Мы сожалеем, но что-то пошло не так',
  description: 'Повторите попытку позже',
  buttonText: 'Вернуться назад',
};

/* eslint-disable max-len */
export const CONFIRM_PAGE_CONTENT = {
  title: 'Передача данных с&nbsp;Мобильным&nbsp;ID',
  description: IS_RAIFFEISEN ? `Передача данных от мобильного оператора
    </br></br>Это значит, что ${label} получит доступ к данным, которые уже есть у мобильного оператора`
    : `${label} запрашивает доступ на передачу данных с&nbsp;Мобильным&nbsp;ID`,
  personalDataCheckbox: {
    textBeforeLink: IS_RAIFFEISEN ? 'Подтверждаю передачу данных и даю свое согласие на '
      : 'Подтверждаю свое согласие на&nbsp;передачу данных от мобильного оператора и даю согласие на',
    linkText: 'обработку персональных данных',
    link: consentUrl,
  },
  promoMessagesCheckbox: {
    text: 'Даю согласие на получение рекламных сообщений от&nbsp;Ив&nbsp;Роше',
  },
  buttonText: 'Согласен',
  validateMsg: {
    required: 'Необходимо согласиться с условиями',
  },
  submitError: 'Что-то пошло не так. Пожалуйста, повторите попытку',
};

export const NEGATIVE_NOT_AVAILABLE = {
  icon: 'person',
  title: 'Мы сожалеем, но услуга доступна только при оформлении номера на&nbsp;физическое лицо',
  texts: [
    {
      textBeforeLink: `Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
      linkText: 'по ссылке',
      link: url,
      download: false,
      target: '_self',
    },
  ],
};

export const NEGATIVE_NOT_PASSPORT = {
  icon: 'passport',
  title: 'Мы сожалеем, но услуга доступна только при оформлении номера на&nbsp;Паспорт&nbsp;РФ',
  texts: [
    {
      textBeforeLink: `Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
      linkText: 'по ссылке',
      link: url,
      download: false,
      target: '_self',
    },
  ],
};

export const NEGATIVE_NOT_AVAILABLE_18 = {
  icon: '18+',
  title: 'Мы сожалеем, но услуга доступна только совершеннолетним',
  texts: [
    {
      textBeforeLink: `Если SIM-карта оформлена не на вас, попробуйте войти со своим номером.<br/><br/>Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
      linkText: 'по ссылке',
      link: url,
      download: false,
      target: '_self',
    },
  ],
};

export const NEGATIVE_DEFAULT = {
  icon: 'forbidden',
  title: 'Мы сожалеем, но что-то пошло не так',
  texts: [
    {
      textBeforeLink: 'Попробуйте выполнить запрос позднее или обратиться в',
      linkText: 'службу поддержки.',
      link: 'https://helpdesk/',
      download: false,
      target: '_self',
    },
    {
      textBeforeLink: `Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
      linkText: 'по ссылке',
      link: url,
      download: false,
      target: '_self',
    },
  ],
};

export const NEGATIVE_CTN_DOCUMENT = {
  icon: 'passport',
  title: 'Мы сожалеем, но услуга доступна только при оформлении номера на&nbsp;Паспорт&nbsp;РФ',
  texts: [
    {
      textBeforeLink: `Попробуйте войти с помощью другого номера.<br/><br/>Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
      linkText: 'по ссылке',
      link: url,
      download: false,
      target: '_self',
    },
  ],
};

export const NEGATIVE_WRONG_CLAIMS = {
  icon: 'person',
  title: 'Пожалуйста, проверьте ваши личные данные',
  text: 'Корректные данные помогут вам автоматически заполнять формы регистрации на сайтах с помощью Мобильного ID',
  buttonAccept: 'Проверить данные',
  buttonDecline: 'Оставить как есть',
};

export const DATA_COMPLETION_TEXTS = {
  title: 'Пожалуйста, дополните или&nbsp;измените данные о&nbsp;себе',
  text: 'Если данные не верны или отсутствуют, внесите изменения и&nbsp;нажмите «Сохранить»',
};

export const UPDATE_REQUEST = {
  icon: 'person',
  title: 'Дополните ваши данные',
  text: 'Это необходимо, чтобы все поля форм регистрации в&nbsp;будущем заполнялись автоматически при помощи Мобильного&nbsp;ID',
  buttonAccept: 'Дополнить данные',
  buttonDecline: 'Дополню позже',
};

export const DATA_UPDATE = {
  title: 'Заполните актуальные данные о себе',
  saveButton: 'Сохранить',
};

export const NUMBERS_LIST_UPDATE = {
  title: 'Для каких номеров сохранить указанные данные?',
  saveButton: 'Сохранить',
};

export const SAVE_BUTTON = 'Сохранить и продолжить';

export const DATE_INPUT_MODAL = {
  text: 'Выберите дату',
  buttonText: 'Готово',
};

export const FINAL = {
  POSITIVE: {
    redirectTo: homePage,
    icon: 'success',
    title: 'Подтверждение получено',
    texts: [
      {
        textBeforeLink: `Сейчас Вы будете перенаправлены на сайт ${label}`,
        linkText: 'по ссылке',
        link: homePage,
        download: false,
        target: '_self',
      },
    ],
  },
  NEGATIVE: {
    redirectTo: homePage,
    icon: 'forbidden',
    title: 'Мы сожалеем, но что-то пошло не так',
    texts: [
      {
        textBeforeLink: 'Попробуйте выполнить запрос позднее или обратиться в',
        linkText: 'службу поддержки.',
        link: 'https://helpdesk/',
      },
      {
        textBeforeLink: `Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
        linkText: 'по ссылке',
        link: homePage,
        download: false,
        target: '_self',
      },
    ],
  },
};

export const BIRTH_DATE_VERIFICATION = {
  title: 'Введите дату своего рождения',
  description: 'Мобильный ID проверит вашу личность и заполнит недостающую информацию автоматически',
  personalDataCheckbox: {
    textBeforeLink: 'Подтверждаю свое согласие на&nbsp;передачу данных от мобильного оператора и даю согласие на',
    linkText: 'обработку персональных данных',
    link: consentUrl,
  },
  promoMessagesCheckbox: {
    text: 'Даю согласие на получение рекламных сообщений от&nbsp;Ив&nbsp;Роше',
  },
  buttonText: 'Отправить',
  validateMsg: {
    required: 'Необходимо согласиться с условиями',
  },
  submitError: 'Что-то пошло не так. Пожалуйста, повторите попытку',
};

export const CODE_VERIFICATION = {
  title: `Введите одноразовый код и&nbsp;подтвердите передачу данных в&nbsp;сторону ${label}`,
  description: 'Код отправлен на номер ',
  buttonText: 'Продолжить',
  timerText: 'Осталось',
  legal: `Нажимая кнопку «Отправить», вы подтверждаете согласие ПАО «ВымпелКом» на обработку и/или передачу абонентского номера ${label} в целях авторизации`,
  verificationError: 'Код неверный. Повторите попытку (осталось {{attempts}})',
  submitError: 'Что-то пошло не так. Пожалуйста, повторите попытку',
  validationMessages: {
    required: 'Поле должно быть заполнено',
    length: 'Недостаточно символов',
  },
};

export const CODE_TIMEOUT = {
  redirectTo: homePage,
  icon: 'forbidden',
  title: 'Время действия кода истекло',
  texts: [
    {
      textBeforeLink: `Выполните повторный запрос.</br></br>Сейчас вы будете перенаправлены на сайт ${label}. Если не хотите ждать, перейдите по `,
      linkText: 'ссылке',
      link: homePage,
      download: false,
      target: '_self',
    },
  ],
};

export const CODE_FAIL = {
  redirectTo: homePage,
  icon: 'forbidden',
  title: 'Мы сожалеем, но код снова введён неверно :(',
  texts: [
    {
      textBeforeLink: `Выполните повторный запрос.</br></br>Сейчас вы будете перенаправлены на сайт ${label}. Если не хотите ждать, перейдите по `,
      linkText: 'ссылке',
      link: homePage,
      download: false,
      target: '_self',
    },
  ],
};

export const EXPIRED = {
  redirectTo: redirectUrl,
  icon: 'timer_off',
  title: 'Время на дополнение персональных данных истекло',
  texts: [
    {
      textBeforeLink: `В целях безопасности мы ограничиваем сессию до 10 минут. Уже внесённые вами данные успешно сохранены.</br></br>Сейчас вы будете перенаправлены на сайт ${label}. Если не хотите ждать, перейдите по `,
      linkText: 'ссылке',
      link: redirectUrl,
      download: false,
      target: '_self',
    },
  ],
};

/* PAYMENT */

export const INSUFFICIENT_BALANCE_ERROR = 'payment_fail';
export const INSUFFICIENT_BALANCE_WARNING = 'Мы не смогли получить баланс, но вы можете попробовать оплатить';

export const PAYMENT_METHODS = {
  title: 'Как будем оплачивать?',
  buttonText: 'Далее',
};

export const CONFIRM_PAYMENT = {
  title: 'Детали платежа',
  details: {
    withdrawal_account: {
      text: 'Счёт списания',
      icon: '//static.beeline.ru/upload/MobileID/images/bee_logo.png',
    },
    balance: {
      text: 'Доступный баланс счёта',
      hideValue: true,
      tooltip: {
        label: '?',
        text: 'Это баланс вашего номера телефона без учёта суммы минимального остатка, которую нельзя использовать для оплаты',
      },
    },
    receiver: {
      text: 'Получатель платежа',
    },
    cost_amount: {
      text: 'Стоимость товара',
    },
    commission: {
      text: 'Комиссия',
    },
    payment_amount: {
      text: 'Сумма платежа',
    },
  },
  buttonText: 'Продолжить',
  altButtonText: 'Пополнить счёт',
};

export const CONFIRM_PAYMENT_MODAL = {
  title: 'Подтвердите оплату',
  description: 'Если вы подтверждаете оплату со счёта телефона, нажмите кнопку «Оплатить»',
  buttonText: 'Оплатить',
  altButtonText: 'Отмена',
};

export const PAYMENT_SUCCESS = {
  title: 'Оплата прошла успешно',
  description: 'Курьер с вами свяжется примерно за 1 час до доставки',
  buttonText: 'Продолжить покупки',
};

export const INSUFFICIENT_BALANCE = {
  icon: 'insufficient',
  title: 'Недостаточно средств',
  description: 'На балансе выбранного вами способа оплаты недостаточно средств. Пополните баланс или выберите другой способ оплаты',
};

export const OUTDATED_LINK = {
  icon: 'error',
  title: 'Ссылка устарела',
  description: 'Закройте вкладку и повторите попытку',
};

export const ADD_PAYMENT_METHOD = {
  title: 'Данные карты',
  rememberCardCheckbox: 'Запомнить карту. Это безопасно.',
};

/* AUTHORIZATION */
export const AUTH = {
  title: 'Введите номер телефона',
  description: `Мы определим оператора и отправим сообщение для подтверждения действий на ресурсе ${label}`,
  buttonText: 'Отправить',
  legal: `Нажимая кнопку «Отправить», вы подтверждаете согласие ПАО «ВымпелКом» на&nbsp;обработку и/или передачу абонентского номера в сторону ${label}`,
  [AUTH_TYPES.PUSH]: {
    title: 'Проверьте экран вашего смартфона',
    description: `Подтвердите вход на сайт ${label}</br>Не закрывайте браузер до авторизации`,
    method: 'Push-уведомление',
    text: 'отправлено на номер',
    icon: 'success',
    tooltip: {
      label: 'Что такое PUSH?',
      value: 'Всплывающие сообщения на экране телефона',
    },
    buttonText: 'Отправить снова',
  },
  [AUTH_TYPES.USSD]: {
    title: 'Проверьте экран вашего смартфона',
    description: `Подтвердите вход на сайт ${label}</br>Не закрывайте браузер до авторизации`,
    method: 'USSD-уведомление',
    text: 'отправлено на номер',
    icon: 'success',
    tooltip: {
      label: 'Что такое USSD?',
      value: 'Всплывающие сообщения на экране телефона',
    },
    buttonText: 'Отправить снова',
  },
  [AUTH_TYPES['PUSH+PIN']]: {
    title: 'Проверьте экран вашего смартфона',
    description: `Вам потребуется ввести PIN-код в PUSH-уведомлении для входа на сайт ${label}`,
    method: 'PUSH-уведомление',
    text: 'отправлено на номер',
    icon: 'success',
    tooltip: {
      label: 'Что такое PUSH?',
      value: 'Всплывающие сообщения на экране телефона',
    },
    buttonText: 'Отправить снова',
  },
  [AUTH_TYPES['PUSH+PIN-SETUP']]: {
    title: 'Проверьте экран вашего смартфона',
    description: 'Для использования Мобильного ID требуется PIN-код. Установить его можно в PUSH-уведомлении.',
    method: 'PUSH-уведомление',
    text: 'отправлено на номер',
    icon: 'success',
    tooltip: {
      label: 'Что такое PUSH?',
      value: 'Всплывающие сообщения на экране телефона',
    },
    buttonText: 'Отправить снова',
  },
  [AUTH_TYPES['SMS+URL']]: {
    title: 'Проверьте входящие СМС на вашем телефоне',
    description: `Перейдите по ссылке в сообщении для перехода на сайт ${label}</br>Не закрывайте браузер до авторизации`,
    method: 'СМС-сообщение',
    text: 'отправлено на номер{{ctn}}',
    icon: 'success',
    buttonText: 'Отправить снова',
  },
  [AUTH_TYPES.SMS]: {
    title: 'Проверьте входящие СМС на вашем телефоне',
    description: `Введите код из SMS и подтвердите вход на сайт ${label}`,
    buttonText: 'Отправить',
  },
  [AUTH_RESULT.SUCCESS]: {
    icon: 'success',
    title: 'Подтверждение получено',
    texts: [
      {
        textBeforeLink: `Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
        linkText: 'по ссылке',
        link: url,
        download: false,
        target: '_self',
      },
    ],
  },
  [AUTH_RESULT.PUSH_DENIED]: {
    icon: 'forbidden',
    title: 'Вы отклонили запрос на вход',
    texts: [
      {
        textBeforeLink: `Если это было ошибочное действие, отправьте новый запрос.</br></br>Сейчас вы будете перенаправлены на&nbsp;сайт ${label}. Если не хотите ждать, перейдите`,
        linkText: 'по ссылке',
        link: url,
        download: false,
        target: '_self',
      },
    ],
  },
};

export const UPDATE_PD_TEXTS = {
  title: 'Пожалуйста, дополните или&nbsp;измените данные о себе',
  text: 'Если данные не верны или отсутствуют, внесите изменения и&nbsp;нажмите «Сохранить»',
};
