import { getCookie } from 'utils/cookie';
import { AUTHORIZATION_COOKIE, AUTHORIZATION_MODE_COOKIE } from 'constants/const';

const authCookie = getCookie(AUTHORIZATION_COOKIE);
const authModeCookie = getCookie(AUTHORIZATION_MODE_COOKIE);

export const CONFIRM_ENDPOINT = '/confirm';
export const CONFIRM_NOTIFICATION_ENDPOINT = '/confirm-notification';
export const CONFIRM_CODE_ENDPOINT = '/verify-with-kyc';
export const SUBSCRIBER_INFO_ENDPOINT = `/subscriber-info/${authCookie}${authModeCookie ? `?mode=${authModeCookie}` : ''}`;
export const AUTHORIZATION_ENDPOINT = '/authorize/continue';

export const GET_MSISDN_BALANCE_ENDPOINT = '/payment/msisdn-balance';
export const PAY_ORDER_ENDPOINT = '/payment/pay-order';
export const PAYMENT_ORDER_STATUS_ENDPOINT = '/payment/order-status';