import { IOS, ANDROID, WINDOWS } from 'constants/const';

export const detectMobileOS = () => {
  if (typeof window === 'undefined') return null;

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return WINDOWS;
  }

  if (/android/i.test(userAgent) && !window.MSStream) {
    return ANDROID;
  }

  if ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || (/Macintosh/.test(userAgent) && navigator.maxTouchPoints)) {
    return IOS;
  }

  return false;
};
